/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Container to limit width */
/* .container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
} */

/* Header */

/* Hero section */

.home-container h1 {
  font-size: 3.5rem;
  margin-top: -50px;
  padding-bottom: 50px;
}
.home-container p {
  font-size: 1.2rem;
  margin-top: -50px;
}

.round-button {
  margin-top: 50px;
  background-color: #f4623a;
  font-size: 5rem;
  border-radius: 50px; /* adjust the value to make the rounding more or less prominent */
  padding: 25px 30px; /* add some padding to the button content to make it look more spacious */
  border: none; /* remove the default button borders */
  cursor: pointer;
}
.round-button h3 {
  color: white;
  font-weight: 900;
  font-size: 1rem;
}
.round-button:hover {
  background-color: #d96c4d;
}
.round-button:hover h3 {
  color: black;
}

#hero {
  /* background: url('hero.jpg') no-repeat center center/cover; */
  background-image: linear-gradient(rgb(66, 66, 221), rgb(223, 217, 217));
  color: #fff;
  color: #fff;
  text-align: center;
  padding: 220px 0;
}

/* Section styling */
/* section {
  padding: 60px 0;
} */

/* About Section */

#abouts {
  background-color: rgb(177, 85, 230);
  /* background-image: linear-gradient(rgb(66, 66, 221), rgb(223, 217, 217)); */
  color: #fff;
  text-align: center;
  padding: 250px 20px; /* Reduce padding for better responsiveness */
}

#abouts h2 {
  margin: 0 0 20px;
  font-size: 2.5rem;
  padding-bottom: 30px;
}

#abouts p {
  padding: 0px 90px; /* Reduce padding for smaller screens */
  font-size: 1.2rem;
  margin-top: -50px;
  color: rgba(246, 244, 244, 0.611);
}

/* Responsive styling */
@media (max-width: 768px) {
  #abouts {
    padding: 250px 20px;
  }

  #abouts h2 {
    font-size: 2rem;
  }

  #abouts p {
    padding: 10px 20px;
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  #abouts {
    padding: 250px 10px;
  }

  #abouts h2 {
    font-size: 1.8rem;
  }

  #abouts p {
    padding: 10px 10px;
    font-size: 1rem;
  }
}

/* Services section */
/* Services section */
#services {
  padding: 110px 0;
}

#services h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
}

#services .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  text-align: center;
  margin: 0 30px;
}

.service {
  text-align: center;
}

.service img {
  width: 100%;
  height: auto; /* Adjust height to auto for better responsiveness */
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(52, 52, 54);
  transition: transform 0.3s;
}

.service img:hover {
  transform: scale(1.05);
}

.service h3 {
  margin-top: 10px;
  font-size: 1.2rem;
}

/* Responsive styling */
@media (max-width: 768px) {
  #services .container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 50px;
  }

  #services h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  #services .container {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  #services h2 {
    font-size: 1.8rem;
  }
}

/* Portfolio section */
#portfolio {
  padding: 110px 0;
}

#portfolio h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  margin: 0 30px;
}

.portfolio-item {
  position: relative;
  text-align: center;
}

.portfolio-item img {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(128, 127, 128);
  transition: transform 0.3s;
}

.portfolio-item img:hover {
  transform: scale(1.05);
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  width: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.portfolio-item:hover .image {
  opacity: 0.3;
}

.portfolio-item:hover .middle {
  opacity: 1;
}

.text {
  background-color: #000000;
  color: white;
  font-size: 16px;
  padding: 16px 8px;
} /* Responsive styling */
@media (max-width: 768px) {
  .portfolio-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 50px;
  }

  #portfolio h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .portfolio-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  #portfolio h2 {
    font-size: 1.8rem;
  }
}

/* Team section */
/* Team section */
#team {
  padding: 110px 0;
  text-align: center;
}

#team h2 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
}

#team .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  margin: 0 30px;
}

.team-member {
  background: #f9f9f9;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(10, 9, 9);
  transition: transform 0.3s;
}

.team-member:hover {
  transform: scale(1.05);
}

.team-member .persion-icon {
  width: 200px;
  height: 200px;
  background-color: #ece9e3;
  border: 3px solid rgb(131, 125, 125);
  border-radius: 50%;
  margin-bottom: 10px;
  transition: transform 0.3s;
}

.team-member h3 {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.team-member p {
  font-size: 1rem;
  color: #666;
}

/* Responsive styling */
@media (max-width: 768px) {
  #team .container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 55px;
  }

  #team h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  #team .container {
    grid-template-columns: 1fr;
    gap: 25px;
  }

  #team h2 {
    font-size: 1.8rem;
  }
}

/* Contact section */
.contact-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 150px;
}
.contact-detail {
  text-align: center;
  margin-bottom: 100px;
}

/* .contact-detail {
  width: 100%;
  height: 80vh;

  background-color: whitesmoke;
} */
.info h1 {
  padding-top: 115px;
  text-align: center;
  padding-bottom: 50px;
  font-size: 2.5rem;
}
.info p {
  text-align: center;
  font-size: 1.3rem;
  margin: 10px 140px 50px 140px;
  color: #777676;
}

.react-icon {
  height: 50px;
  width: 50px;
  color: #666;
}
.mobile,
.email {
  font-size: 1.5rem;
  padding: 5px 90px;
}
.email a {
  text-decoration: none;
  color: #f4623a;
}
.email a:hover {
  color: #f13906;
  text-decoration: underline;
}

/* Enquiry.jsx css */
.enquiry-form-container {
  width: 100%;
  max-width: 600px;
  margin: 0px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  background-image: linear-gradient(rgb(231, 212, 235), rgb(152, 211, 23));
}

.enquiry-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button[type='submit'] {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

button[type='submit']:hover {
  background-color: #0056b3;
}
/* Enquiry.jsx css end */

/* Media Quary for Contact */
@media (max-width: 480px), (max-width: 769px) {
  .contact-section {
    display: inline;
    margin: 0 0px;
    text-align: center;
  }
  .contact-detail {
    text-align: center;
    margin-bottom: 10px;
  }
  .info p {
    text-align: center;
    font-size: 1.3rem;
    margin: 10px 10px 50px 10px;
    color: #777676;
  }
  .mobile,
  .email {
    font-size: 1.2rem;
    /* padding: 5px 90px; */
  }
}

/* hr */
hr.divider {
  margin: 0 47% 80px 47%;
  /* max-width: 5rem; */
  border-width: 0.2rem;
  padding-bottom: 50px;
  border-color: #f4623a;
}
.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: -2rem !important;
}
hr {
  text-align: center;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 480px), (max-width: 769px) {
  hr.divider {
    margin: 0 40% 50px 40%;
  }
}
/* Footer */
footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 20px 0;
}

footer p {
  margin: 0;
  font-size: 1rem;
}
