body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.mainhome-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgb(66, 66, 221);
  color: rgb(0, 0, 0);
}
.mainhome-header.scrolled {
  background: black; /* New background color when scrolled */
  /* color: white; */
}
.mainhome-header.scrolled .mainhome-logo h1 {
  color: white; /* New text color when scrolled */
}
.mainhome-header.scrolled .mainhome-nav-links a {
  transition: color 0.3s;
  color: white; /* New link color when scrolled */
}
.mainhome-header.scrolled .mainhome-nav-links a:hover {
  color: #f0a500; /* New link color when scrolled */
}
.mainhome-header.scrolled .mainhome-menu-icon {
  color: white; /* New icon color when scrolled */
}

.mainhome-logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}
.mainhome-logo h1 {
  margin: 0;
  font-size: 3rem;
  color: black;
}

.mainhome-logo img {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Add spacing between logo and text */
  border-radius: 50%;
}

.mainhome-nav-links {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 250px; /* Set your desired width */
  position: fixed;
  top: 80px;
  left: -250px; /* Off-screen initially */
  bottom: 0;
  line-height: 50px;
  background-color: black;
  padding-top: 10px; /* Account for header height */
  transition: left 0.3s ease; /* Smooth transition effect */
}
.mainhome-header.scrolled .mainhome-nav-links {
  background-color: black;
}

.mainhome-nav-links.open {
  left: 0; /* Slide in from left when open */
}

.mainhome-nav-links a {
  color: black;
  text-decoration: none;
  font-size: 1rem;
  padding-left: 20px;
  transition: color 0.3s;
}
.mainhome-nav-links a:hover {
  color: #f0a500;
}

.mainhome-menu-icon {
  font-size: 24px;
  cursor: pointer;
}

/* Mobile view */
@media (min-width: 769px) {
  .mainhome-menu-icon {
    display: none; /* Hide hamburger icon on larger screens */
  }

  .mainhome-nav-links {
    display: flex;
    flex-direction: row;
    position: static;
    background-color: transparent;
    padding-top: 0;
    left: 0;
    width: auto;
  }

  .mainhome-nav-links a {
    padding: 5px 5px; /* Adjust spacing */
  }
}

@media (max-width: 980px) {
  .mainhome-menu-icon {
    color: white;
  }
  .mainhome-header {
    background-color: black;
    height: 80px;
  }

  .mainhome-logo h1 {
    color: white;
    font-size: 2rem;
  }
  .mainhome-logo img {
    /* display: none; */
    width: 75px;
  }
  .mainhome-nav-links a {
    color: white;
  }
}

@media (max-width: 480px) {
  .mainhome-menu-icon {
    color: white;
  }
  .mainhome-header {
    background-color: black;
    height: 80px;
  }

  .mainhome-logo h1 {
    color: white;
    font-size: 1.8rem;
  }
  .mainhome-logo img {
    /* display: none; */
    width: 70px;
  }
  .mainhome-nav-links a {
    color: white;
  }
}
